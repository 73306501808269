import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import * as moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { getReserveByIdQuery } from "../../../queries";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Collapse,
  Row,
  Spinner,
} from "reactstrap";
import { prettify } from "../../../helpers/price-formater";
import { useTranslation } from "react-i18next";
import Textfield from "../ui/text-field";
import ModalConfirmation from "../ui/modal";

const ReserveSingle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { data, isLoading } = useQuery({
    ...getReserveByIdQuery(id),
  });

  const toggleModal = (index) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [index]: !prevOpen[index],
    }));
  };

  const statusLabel = (state) => {
    switch (state) {
      case 0:
        return t("reserve_created");
      case 1:
        return t("reserve_underway");
      case 2:
        return t("reserve_sent");
      case 3:
        return t("reserve_complated");
      case 4:
        return t("reserve_cancelled");
      default:
        return t("reserve_refund");
    }
  };
  const calculateOrderTotalPrice = (order) => {
    let total = 0;
    order?.subOrders?.forEach((subOrder) => {
      subOrder?.items?.forEach((item) => {
        const itemPrice = item?.variation?.prices?.find(
          (price) => price.type === "Price"
        )?.value;
        if (itemPrice) {
          total += itemPrice * item?.count;
        }
      });
    });
    return total;
  };

  const calculateSubOrderTotalPrices = (order) => {
    return order?.subOrders?.map((subOrder) => {
      let total = 0;
      subOrder?.items?.forEach((item) => {
        const itemPrice = item?.variation?.prices?.find(
          (price) => price.type === "Price"
        )?.value;
        if (itemPrice) {
          total += itemPrice * item?.count;
        }
      });
      return total;
    });
  };

  const totalPrice = calculateOrderTotalPrice(data);
  const subOrderTotalPrice = calculateSubOrderTotalPrices(data);
  return (
    <div className="page-content">
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Spinner size="lg" />
        </div>
      ) : (
        <div>
          <div className="d-flex just">
            <div className="d-flex align-items-start gap-3 mb-2">
              <i
                className="ri-arrow-left-line fs-3 cursor-pointer"
                onClick={() => navigate(-1)}
              ></i>
              <h2>
                {t("reserve")} № {data?.id}
              </h2>
            </div>
          </div>
          <Row className="justify-content-around">
            <Col xs="12" lg="5" className="mb-3">
              {data?.subOrders?.map((item, i) => (
                <Card key={i} className="mb-3" style={{ borderRadius: "1rem" }}>
                  <CardHeader
                    className="d-flex align-items-start justify-content-between w-100 m-0"
                    style={{
                      borderTopLeftRadius: "1rem",
                      borderTopRightRadius: "1rem",
                      
                    }}
                  >
                    <div className="d-flex flex-column gap-1">
                      <strong>{item?.organizationName}</strong>
                      <strong>+{item?.organizationPhoneNumber}</strong>
                    </div>

                    <div className="d-flex flex-column align-items-end">
                      <div className="d-flex gap-1 align-items-center">
                        <strong>{t("subOrderTotalPrice")}:</strong>
                        <div>{prettify(subOrderTotalPrice[i])}</div>
                      </div>

                      <div className="d-flex gap-1 align-items-center">
                        <strong>{t("status")}:</strong>
                        <div>{statusLabel(item.state)}</div>
                      </div>
                      <Button
                        hidden={item?.state !== 4}
                        className="p-0 m-0"
                        color="link"
                        onClick={() => toggleModal(i)}
                      >
                        {t("read_the_reason")}
                      </Button>
                    </div>
                  </CardHeader>
                  <CardBody className="d-flex gap-5 flex-column">
                    {item?.items?.map((child, index) => (
                      <div
                        key={index}
                        className="d-flex gap-5 justify-content-between"
                      >
                        <div className="d-flex gap-3 align-items-start">
                          <img
                            style={{
                              maxWidth: "100px",
                              width: "100px",
                              maxHeight: "100px",
                              objectFit: "cover",
                              borderRadius: "5px",
                            }}
                            src={child.variation?.files?.[0]?.url}
                            alt={child.variation?.files?.[0]?.id}
                          />
                          <div className="d-flex flex-column gap-1">
                            <div style={{ width: "250px" }}>
                              {child.variation?.product.name}
                            </div>
                            <strong>
                              {prettify(
                                child?.variation?.prices?.find(
                                  (price) => price.type === "Price"
                                )?.value
                              )}
                            </strong>

                            <div>x{child?.count}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </CardBody>

                  <ModalConfirmation
                    title={item?.organizationName}
                    isOpen={open[i]}
                    toggleConfirmation={() => toggleModal(i)}
                  >
                    <p>{item?.reason}</p>
                  </ModalConfirmation>
                </Card>
              ))}
            </Col>

            <Col xs="12" lg="5" className="mb-3">
              <Card
                style={{
                  position: "sticky",
                  top: "85px",
                  zIndex: 999,
                  borderRadius: "1rem",
                }}
              >
                <CardHeader
                  style={{
                    borderTopLeftRadius: "1rem",
                    borderTopRightRadius: "1rem",
                  }}
                >
                  <h3>{t("order_information")}</h3>
                </CardHeader>
                <CardBody>
                  <Textfield
                    name={"phone_number"}
                    source={"+" + data?.phoneNumber}
                  />
                  <Textfield
                    name={"client_full_name"}
                    source={data?.fullName}
                  />
                  <div hidden={data?.deliveryType === 1}>
                    <Textfield name={"address2"} source={data?.address} />
                    <Textfield name={"region"} source={data?.regionName} />
                    <Textfield name={"district"} source={data?.destrictName} />
                  </div>
                  <Textfield
                    name={"total_price"}
                    source={prettify(totalPrice)}
                  />
                  <Textfield
                    name={"delivery_status"}
                    source={
                      data?.deliveryType === 0 ? t("delivery") : t("pickup")
                    }
                  />
                  <Textfield
                    name={"created_date"}
                    source={moment(data?.createdDate).format("DD.MM.YYYY")}
                  />
                  <Textfield name={"Comments"} source={data?.comment} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default ReserveSingle;
