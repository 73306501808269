import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Form,
  Button,
  Spinner,
} from "reactstrap";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { uploadPhoto, addServiceItem, editServiceItem } from "../../../api";
import { Input } from "../../../Components/atoms/input";
import { useNavigate } from "react-router-dom";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { getTabQuery } from "../../../queries";
import Dropzone from "react-dropzone";
import { toast, ToastContainer } from "react-toastify";

const values = {
  fileId: "",
  order: "",
  link: "",
};

const ServiceItemAdd = () => {
  const [loading, setLoading] = useState(false);
  const [filePreviews, setFilePreviews] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, parentId } = useParams();
  const itemId = parseInt(id);
  const parent = parseInt(parentId);
  const QueryClient = useQueryClient();

  const schema = createSchema({
    files: "",
    order: "numberOrder",
    link: "name",
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    setError,
  } = useHookForm(values, schema);

  const { data: tab } = useQuery({
    ...getTabQuery(parent),
    enabled: parent !== undefined,
  });

  const handleFileUpload = async (acceptedFiles) => {
 

    const newFiles = acceptedFiles.map((file) => ({
      preview: URL.createObjectURL(file),
    }));
    setFilePreviews(...newFiles.map((f) => f.preview));
    setValue("files", acceptedFiles[0]);
  };

  const onSubmit = handleSubmit((res) => {
    if (!watch("files")) {
      return setError("files", {
        type: "manual",
        message: t("required"),
      });
    }

    const formData = new FormData();
    formData.append("files", watch("files"));

    uploadPhoto(formData).then((file) => {
      setLoading(false);
      const datas = {
        order: res.order,
        link: res.link,
        fileId: file[0].id,
      };

      itemId
        ? editServiceItem(parent, itemId, datas)
            .then(() => {
              setLoading(false);
              navigate(`/service/${parent}`);
            })
            .catch((err) => alert(err.data?.error))
        : addServiceItem(parent, datas)
            .then(() => {
              setLoading(false);
              navigate(`/service/${parent}`);
              QueryClient.invalidateQueries(["tab"]);
            })
            .catch((err) => alert(err.data?.error));
    });
  });

  document.title = "Categories Create | Taqsim";
  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col xl={8}>
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center">
                  <h5
                    className="card-title flex-grow-1 mb-0"
                    style={{ fontSize: "20px", fontWeight: 700 }}
                  >
                    {id ? t("edit_element") : t("add_element")}
                  </h5>
                </div>
              </CardHeader>
              <CardBody>
                <Form action="#">
                  <div className="d-flex gap-4 flex-wrap flex-column">
                    <Input
                      control={control}
                      errors={errors}
                      name="order"
                      label="order"
                      isInteger
                    />
                    <Input
                      control={control}
                      errors={errors}
                      name="link"
                      label="link"
                    />
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        handleFileUpload(acceptedFiles)
                      }
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps({ className: "dropzone" })}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            height: "80px",
                            border: "2px dashed #cccccc",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                        >
                          {!filePreviews && (
                            <div className="d-flex align-items-center justify-content-center">
                              <i className="bx bx-image-add display-4"></i>
                            </div>
                          )}
                          {filePreviews && (
                            <img
                              src={filePreviews}
                              alt={`Img`}
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                objectFit: "cover",
                                borderRadius: "5px",
                              }}
                            />
                          )}
                        </div>
                      )}
                    </Dropzone>
                  </div>
                  <p  className="mt-1">{t("Recommended image size 858x308px")}</p>
                  {errors.files && (
                    <span className="text-danger">{errors.files.message}</span>
                  )}

                  <Button
                    color="success"
                    className="btn btn-success w-100 mt-3"
                    type="submit"
                    onClick={onSubmit}
                  >
                    {loading ? (
                      <Spinner size="sm" className="me-2">
                        {" "}
                        Loading...{" "}
                      </Spinner>
                    ) : null}
                    {t("save")}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ServiceItemAdd;
