import { Controller } from "react-hook-form";
import { FormFeedback, Label, Input as InputB } from "reactstrap";
import { useTranslation } from "react-i18next";
import { layoutModeTypes } from "../constants/layout";
import { useEffect, useState } from "react";

const onKeyDown = (type, e, isInteger) => {
  switch (type) {
    case "number":
      if (
        e.key === "e" ||
        e.key === "E" ||
        e.key === "+" ||
        e.key === "-" ||
        e.key === "," ||
        (isInteger ? e.key === "." : false)
      ) {
        e.preventDefault();
      }
      break;
    default:
      return null;
  }
};



export const InputLabel = ({
  name,
  control,
  errors,
  label,
  inputProps = {
    type: "text",
    
  },
  className,

  leftElement,
  rightElement,
  isInteger,
  maxLength,
}) => {
  const { t } = useTranslation();

  
  return (
    <div className="position-relative w-100 mb-3">
      {label && (
        <Label htmlFor="email" className="form-label" style={{
            position: "absolute",
            top: "-8px",
            left: "10px",
            padding: "0 5px",
            fontSize: "12px",
            zIndex: 10,
        //   color: "#000"
            
          }} for={name}>
          {t(label)}
        </Label>
      )}
      {control ? (
        <Controller
          name={name}
          control={control}
          className={className}
          
          render={({ field }) => (
            <div className="input-group">
              {leftElement && (
                <span className="input-group-text">{leftElement}</span>
              )}
              <InputB
              
                type="text"
                
               
                className="form-control"
                onKeyDown={(e) => onKeyDown(inputProps.type, e, isInteger)}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  if (maxLength) {
                    if (e.target.value.length <= maxLength) {
                      field.onChange(e);
                    }
                  } else {
                    field.onChange(e);
                  }
                }}
                {...inputProps}
                {...field}
              />
              {rightElement && (
                <span className="input-group-text">{rightElement}</span>
              )}
            </div>
          )}
        />
      ) : (
       
     
        
          <div className="input-group">
            {leftElement && (
              <span className="input-group-text">{leftElement}</span>
            )}
            <InputB
            
              type="text"
              
             
              className="form-control"
              onKeyDown={(e) => onKeyDown(inputProps.type, e, isInteger)}
              onWheel={(e) => e.target.blur()}
             
              {...inputProps}
           
            />
            {rightElement && (
              <span className="input-group-text">{rightElement}</span>
            )}
          </div>
   
      )}
      {errors?.[name] && (
        <div className="invalid-feedback" style={{ display: "block" }}>
          {t(errors[name]?.message)}
        </div>
      )}
    </div>
  );
};
