import { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import * as moment from "moment";
import classnames from "classnames";
import debounce from "lodash/debounce";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Button,
  Input,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import ExportCSVModal from "../../../Components/Common/ExportCSVModal";
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { getOrdersQuery } from "../../../queries/index";
import "react-toastify/dist/ReactToastify.css";
import { getOrders } from "../../../api";
import { CheckStatus } from "../../../helpers/findParentCategoryId";

const schema = createSchema({});
const values = {
  page: 1,
  size: 20,
  phone: "",
  isInstallment: "",
  sortBy: "id",
  desc: true,
};

const Orders = () => {
  const [allDataLoading, setAllDataLoading] = useState(false);
  const [dataForDownload, setDataForDownload] = useState([]);
  const [exportModal, setExportModal] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { watch, setValue } = useHookForm(values, schema);

  const { data, isFetched } = useQuery({
    ...getOrdersQuery({
      ...watch(),
    }),
  });

  // Fetch All Data for Excel export
  const getAllData = () => {
    setAllDataLoading(true);
    getOrders({
      ...watch(),
      size: data?.pagination?.TotalCount,
    })
      .then((res) => {
        setDataForDownload(res?.orders);
        setExportModal(true);
      })
      .finally(() => setAllDataLoading(false));
  };

  const debouncedSetValue = useMemo(
    () =>
      debounce((field, value) => {
        setValue(field, value);
      }, 500),
    [setValue]
  );

  const onSearchChange = (e) => {
    const { value } = e.target;
    debouncedSetValue("phone", value);
  };
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token]);

  // Column
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: false,
      },
      {
        Header: t("region"),
        accessor: "region",
        filterable: false,
      },
      {
        Header: t("merchant"),
        accessor: "organization",
        filterable: false,
      },
      {
        Header: t("full_name"),
        accessor: "fullname",
        filterable: false,
      },
      {
        Header: t("type"),
        accessor: "isInstallment",
        Cell: (order) => (
          <>
            {order.row.original.isInstallment
              ? t("installment")
              : t("compensation")}
          </>
        ),
      },
      {
        Header: t("phone"),
        accessor: "phone",
        filterable: false,
      },
      {
        Header: t("created_date"),
        accessor: "orderDate",
        Cell: (order) => (
          <>
            {moment(order.row.original.createdDate).format("DD.MM.YYYY")},{" "}
            <small className="text-muted">
              {moment(order.row.original.createdDate).format("HH:mm")}
            </small>
          </>
        ),
      },
      {
        Header: t("status"),
        accessor: "status",
        Cell: (cell) => CheckStatus(cell.value, t),
      },

      {
        Header: "",
        accessor: "action",
        Cell: (order) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <Link
                  to={`/orders/${order.row.original.id}`}
                  className="text-primary d-inline-block"
                >
                  <i className="ri-eye-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );


  return (
    <div className="page-content">
      <ExportCSVModal
        show={exportModal}
        onCloseClick={() => setExportModal(false)}
        data={dataForDownload}
        fileName="ЗАЯВКИ"
      />
      <Container fluid>
        <BreadCrumb title={t("orders")} pageTitle={t("home")} />
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("orders")}</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap">
                      <Button
                        type="button"
                        className="btn"
                        color="primary"
                        onClick={getAllData}
                        disabled={allDataLoading}
                      >
                        <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                        {t(allDataLoading ? "downloading" : "export")}
                      </Button>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: watch("isInstallment") === "" },
                          "fw-semibold"
                        )}
                        onClick={() => setValue("isInstallment", "")}
                        href="#"
                      >
                        <i className="ri-store-2-fill me-1 align-bottom"></i>{" "}
                        {t("all_orders")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: watch("isInstallment") === false },
                          "fw-semibold"
                        )}
                        onClick={() => setValue("isInstallment", false)}
                        href="#"
                      >
                        <i className="ri-checkbox-circle-line me-1 align-bottom"></i>{" "}
                        {t("compensation")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: watch("isInstallment") === true },
                          "fw-semibold"
                        )}
                        onClick={() => setValue("isInstallment", true)}
                        href="#"
                      >
                        <i className="ri-arrow-left-right-fill me-1 align-bottom"></i>{" "}
                        {t("installment")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <CardBody className="border border-dashed border-end-0 border-start-0 pt-4">
                    <form>
                      <Row>
                        <Col sm={5}>
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <input
                              id="search-bar-0"
                              type="text"
                              className="form-control search /"
                              placeholder={t("phone")}
                              defaultValue={watch("phone")}
                              onChange={onSearchChange}
                            />
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </Col>
                      </Row>
                    </form>
                  </CardBody>
                  {isFetched ? (
                    <>
                      <TableContainer
                        pagination={{
                          currentPage: watch("page"),
                          totalPages: data?.pagination?.TotalPages,
                          onChange: (page) => setValue("page", page),
                        }}
                        columns={columns}
                        data={data?.orders || []}
                        isGlobalFilter={false}
                        isAddUserList={false}
                        divClass="table-responsive table-card mb-1"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light text-muted"
                      />
                      <Input
                        type="select"
                        name="pageSize"
                        id="pageSize"
                        value={watch("size")}
                        onChange={(e) => {
                          const newSize = parseInt(e.target.value);
                          setValue("size", newSize);
                          setValue("page", 1);
                        }}
                        className="w-auto"
                      >
                        <option value={20}>{t("20")}</option>
                        <option value={50}>{t("50")}</option>
                        <option value={100}>{t("100")}</option>
                      </Input>
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Orders;
