import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Button,
  Label,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getModerationQuery, getProductQuery } from "../../../queries/index";
import { useTranslation } from "react-i18next";
import { Input } from "../../../Components/atoms/input";
import useHookForm from "../../../hooks/useHookForm";
import { createModerationProduct } from "../../../api";
import { Textarea } from "../../../Components/atoms/textarea";
import { InputLabel } from "../../../Components/atoms/inputLabel";

const values = {};
const schema = {};

const ModerProductView = () => {
  const { moderId } = useParams();
  const { t } = useTranslation();
  const QueryClient = useQueryClient();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { data: product } = useQuery({
    ...getModerationQuery(moderId),
  });
  const {
    control,
    watch,
    formState: { errors },
    setValue,
  } = useHookForm(values, schema);

  const handleClick = (status) => {
    setLoading(true);
    createModerationProduct({ productId: moderId, status }).then(() => {
      setLoading(false);
      navigate("/moder-products");
      QueryClient.invalidateQueries(["moderations"]);
    });
  };

  useEffect(() => {
    if (product) {
      setValue("categoryName", product.category.name);
      product.names.map((item) =>
        setValue(`name${item.languageCode}`, item.text)
      );
      product.descriptions.map((item) =>
        setValue(`description${item.languageCode}`, item.text)
      );
      product.variations?.[0].prices.map((item) =>
        setValue(`price_${item.type}`, item.value)
      );
      product.variations?.[0].attributeValues.map((item) =>
        setValue(`value_${item.attribute?.name}`, item.value)
      );
    
      
    }
  }, [product]);

  document.title = "Moder Product Details | Taqsim";
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={t("products")} pageTitle={t("home")} />
        {product?.id && (
          <Row>
            <Col className="d-flex justify-content-center">
              <Card className="w-75">
                <CardHeader>
                  <div className="d-flex align-items-center justify-content-between">
                    <h5
                      className="card-title flex-grow-1 mb-0"
                      style={{ fontSize: "20px", fontWeight: 700 }}
                    >
                      {t("products")}
                    </h5>
                    <div className="d-flex gap-3">
                      <Button
                        color="danger"
                        style={{ width: "150px" }}
                        outline
                        onClick={() => handleClick(3)}
                      >
                        {"✖ Отклонить товар"}
                      </Button>
                      <Button
                        color="success"
                        className="btn-animation"
                        data-text={"✔ Одобрить товар"}
                        style={{ width: "150px" }}
                        onClick={() => handleClick(1)}
                      >
                        <span>{"✔ Одобрить товар"}</span>
                      </Button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="d-flex justify-content-between">
                  <Row className="d-flex flex-column gap-3 col-6">
                    <Input
                      name="categoryName"
                      control={control}
                      errors={errors}
                      label="category_name"
                      inputProps={{
                        disabled: true,
                      }}
                    />
                    {product?.names
                      ?.filter(
                        (item) =>
                          item.languageCode !== "en" &&
                          item.languageCode !== "uz-Cyrl-QQ"
                      )
                      .map((item) => (
                        <Input
                          key={item.languageCode}
                          name={`name${item.languageCode}`}
                          control={control}
                          errors={errors}
                          label={`name_${item.languageCode}`}
                          inputProps={{
                            disabled: true,
                            
                          }}
                        />
                      ))}
                    {product?.descriptions
                      ?.filter(
                        (item) =>
                          item.languageCode !== "en" &&
                          item.languageCode !== "uz-Cyrl-QQ"
                      )
                      .map((item) => (
                        
                        <Textarea
                          key={item.languageCode}
                          name={`description${item.languageCode}`}
                          // control={control}
                          errors={errors}
                          label={`description_${item.languageCode}`}
                          inputProps={{
                            disabled: true,
                            value: item.text
                          }}
                        />
                      ))}
                    {product?.variations[0]?.prices?.map((item) => (
                      <Input
                        key={item.id}
                        name={`price_${item.type}`}
                        control={control}
                        errors={errors}
                        label={`${item.type === "Sale" ? "MSRP" : item.type}`}
                        inputProps={{
                          disabled: true,
                        }}
                      />
                    ))}
              <div
  style={{
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)", 
    gap: "1rem",
  }}
>
  {product?.variations[0]?.files?.map((item) => (
    <div
      style={{
        width: "200px", 
        height: "200px", 
      }}
      id="img2"
      key={item.languageCode}
    >
      <img
        src={item.url}
        className="img-fluid w-100"
        alt="Responsive"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover", 
        }}
      />
    </div>
  ))}
</div>

                  </Row>
                  <Row className="d-flex flex-column gap-3 col-6">
                    {product?.variations[0]?.attributeValues
                      .map((item) => (
                    item.attribute.isVisible && (
                      !item.attribute.isValueTranslated ? (
                        <Input
                        key={item.id}
                        name={`value_${item.attribute?.name}`}
                        control={control}
                        errors={errors}
                        label={`${item.attribute?.name}`}
                        inputProps={{
                          disabled: true,
                        }}
                      />
                      ) : item.attribute.isValueTranslated && (
                        <div>
                          <Label>{item.attribute?.name}</Label>
                     
                    <div className="d-flex align-items-center gap-2">
                      
                         {
                          item.valueTranslations?.filter(
                            (item) =>
                              item.languageCode !== "en" &&
                              item.languageCode !== "uz-Cyrl-QQ"
                          ).map((translation, i) => (
                            <InputLabel
                            key={translation.languageCode}
                            name={`translation${translation.languageCode}`
                            }
                            
                            errors={errors}
                            label={translation.languageCode === "uz-Latn-UZ" ? t("uz") : translation.languageCode === "uz-Cyrl-UZ" ? t("cryl") : t(translation.languageCode)}
                            inputProps={{
                              disabled: true,
                              value: translation.text
                            }}
                           
                          />
                          ))
                         }
                          
                    </div>
                    </div>
                      )
                    )
                      ))}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default ModerProductView;
