import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Label, Input } from "reactstrap";

const RadioButtonInput = ({ state, setState, label, id, disabled, dataType  }) => {
  const { t } = useTranslation();

  const handleRadioChange = (value) => () => {
    setState(value);
  };
  useEffect(() => {
    if (dataType === dataType) {
      setState(false); 
    }
  }, [dataType, setState]);
  return (
    <>
      <Label>{t(label)}</Label>
      <div className="form-check mb-2">
        <Input
          className="form-check-input"
          type="radio"
          name={`flexRadioDefault${id}`}
          id={`flexRadioDefault${id}`}
          checked={!state}
          onChange={handleRadioChange(false)}
          disabled={disabled}
        />
        <Label>{t("no")}</Label>
      </div>
      <div className="form-check">
        <Input
          className="form-check-input"
          type="radio"
          name={`flexRadioDefault${id}`}
          id={`flexRadioDefault${id}`}
          checked={state}
          onChange={handleRadioChange(true)}
          disabled={disabled}
        />
        <Label>{t("yes")}</Label>
      </div>
    </>
  );
};

export default RadioButtonInput;
